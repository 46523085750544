<template>
  <div class="login-wrap">
    <div class="login">
      <div class="left">
        <div class="title">两种登录模式可选:</div>
        <div class="kuang">
          <img src="@/assets/images/logo.png" alt="" />
          <span>平台账号</span>
        </div>
        <div class="items">
          <img src="@/assets/images/litttleStar.png" alt="" /><span
            >手机号注册，操作便捷</span
          >
        </div>
        <div class="items">
          <img src="@/assets/images/litttleStar.png" alt="" /><span
            >资产托管于平台，管理成本低</span
          >
        </div>

        <div class="kuang">
          <img src="@/assets/images/logo.png" alt="" />
          <span>Matemask <span style="color: #9f9f9f">（即将上线）</span></span>
        </div>
        <div class="items">
          <img src="@/assets/images/litttleStar.png" alt="" />

          <span>无需注册，使用钱包地址直接登录</span>
        </div>
        <div class="items">
          <img src="@/assets/images/litttleStar.png" alt="" />
          <span>资产存于链上，交易更自由</span>
        </div>
      </div>
      <div class="formBox">
        <div class="formTit">
          <a class="logo" title="几人作" to="/home">
            <img src="@/assets/images/logo.png" alt="" />
            <el-link
              :underline="false"
              style="font-size: 18px; font-weight: 600; color: #fff"
              >幾人作</el-link
            >
          </a>
          <span>人工智能辅助创作平台</span>
        </div>
        <p style="color: #585858; padding: 18px">新手机号验证后将自动注册</p>
        <div class="phone">
          <el-input v-model="form.mobile" placeholder="请输入手机号">
            <template slot="prepend">+86</template>
          </el-input>
        </div>

        <div class="yanzhengma">
          <el-input
            style="width: 200px; margin-right: 30px"
            v-model="form.verifyCode"
            placeholder="请输入验证码"
            @keyup.enter.native="loginSubmit"
          ></el-input>
          <el-button
            size="small"
            round
            :disabled="code.dis"
            type="text"
            @click.prevent="getCode"
          >
            <span v-if="code.show">发送短信验证码</span>
            <span style="color: #828282; font-weight: 600" v-else
              >{{ code.count }}s后重发</span
            >
          </el-button>
        </div>
        <el-button
          style="width: 100%; color: #000; margin: 18px 0"
          type="primary"
          round
          @click.prevent="loginSubmit"
          >登录</el-button
        >

        <el-checkbox v-model="checked">我已仔细阅读并同意几人作</el-checkbox>
        <el-button type="text">《用户协议》</el-button>
        <el-button type="text">《隐私政策》</el-button>

        <div class="msg" v-show="noRead">
          <span>请阅读并同意协议</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendMessage } from "@/api";
import { setSm4 } from "@/utils/sm4Util";
export default {
  name: "Login",
  data() {
    return {
      //收集用户名
      form: {},
      code: {
        dis: false,
        show: true,
        timer: null, //设置计时器,
        count: "",
      },

      checked: false,
      noRead: false,
    };
  },
  methods: {
    getCode() {
      let param = {
        mobile: this.form.mobile,
      };
      sendMessage(param).then((res) => {
        console.log(res);
      });
      let TIME_COUNT = 60;
      if (!this.code.timer) {
        this.code.count = TIME_COUNT;
        this.code.show = false;
        this.code.dis = true;
        this.code.timer = setInterval(() => {
          if (this.code.count > 0 && this.code.count <= TIME_COUNT) {
            this.code.count--;
          } else {
            this.code.dis = false;
            this.code.show = true;
            clearInterval(this.code.timer);
            this.code.timer = null;
          }
        }, 1000);
      }
    },
    async loginSubmit() {
      if (!this.checked) {
        this.noRead = true;
        return;
      }
      //获取用户名|密码
      try {
        const param ={
          mobile:setSm4(this.form.mobile),
          verifyCode:this.form.verifyCode
        } 
        await this.$store.dispatch("userLogin", param);
        //判断登录的组件URL：是否有query参数【即为用户未登录时候，想去而没有去成的路由】
        let toPath = this.$route.query.redirect || "/home";
        this.$router.push(toPath);
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login-wrap {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: url(../../assets/images/loginbg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    .left {
      .title {
        font-family: Alibaba PuHuiTi 2;
        font-size: 20px;
        color: #fffbfb77;
        height: 60px;
      }
      .kuang {
        display: flex;
        height: 48px;
        width: 190px;
        margin: 18px 0;
        align-items: center;
        border-radius: 20px;
        padding-left: 20px;
        background: #06070c70;
        color: #fffbfb;
        img {
          width: 30px;
          margin-right: 10px;
        }
      }
      .items {
        padding-left: 20px;
        height: 30px;
        width: 240px;
        display: flex;
        align-items: center;
        color: #fffbfb77;
        img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
    .formBox {
      width: 454px;
      height: 400px;
      border-radius: 35px;
      position: relative;
      box-sizing: border-box;
      padding: 30px 40px;
      background: #06070c70;
      /deep/ .el-input {
        height: 48px;
        border: none;
        box-sizing: border-box;
        .el-input__inner {
          height: 48px;
          display: block;
          background: rgba(242, 242, 242, 0.1294);
          border: 1px solid #8e8e8e;
          border-radius: 24px;
        }
      }
      /deep/ .el-checkbox__inner {
        border-radius: 8px;
      }
      .phone {
        margin-bottom: 20px;
        /deep/ .el-input {
          position: relative;
          .el-input-group__prepend {
            position: absolute;
            top: 10px;
            left: -4px;
            height: 28px;
            line-height: 28px;
            background: none;
            border: none;
            border-right: 1px solid #8e8e8e;
            padding-right: 36px;
          }
          .el-input__inner {
            display: block;
            padding-left: 60px;
          }
        }
      }
      .msg {
        position: absolute;
        top: 140px;
        left: -60px;
        height: 48px;
        line-height: 48px;
        width: 120px;
        align-items: center;
        border-radius: 20px;
        padding-left: 20px;
        background: #000;
        opacity: 0.9;
        color: #fffbfb;
      }
      .formTit {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
          display: flex;
          align-items: center;
          img {
            width: 36px;
            margin-right: 10px;
          }
        }
        span {
          color: #9f9f9f;
        }
        .yanzhengma {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>